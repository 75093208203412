const appConfig = {
  app: {
    name: "Bengaluru Gold Festival",
    version: "1.0.0",
  },
  baseUrl: "https://jewellers-uat.goldfestival.in/api/method",
  baseUrlImg: "https://jewellers-uat.goldfestival.in",
  token: "token cf2a4084ac49739:75d19a8a2c64764",
};

export default appConfig;
